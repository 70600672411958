/* eslint-disable no-unused-vars */
import "./App.css";
import firebase from "./FirebaseConfig";

function App() {
  return (
    <div className="App">
      <div>
        <h1>Figpage</h1>
      </div>
    </div>
  );
}

export default App;
